import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Text from "../../../components/shared/text";
import Heading from "../../../components/shared/heading";
import Link from "../../../components/shared/link";
import Social, { SocialLink } from "../../../components/shared/social";
import {
  FooterWrap,
  FooterMiddle,
  FooterMiddleLeft,
  FooterMiddleRight,
  FooterBottom,
  FooterBottomLeft,
  FooterBottomRight,
} from "./footer.stc";

const Footer = ({
  headingStyle,
  emailTextStyle,
  emailLinkStyle,
  widgetTitleStyle,
  addresTextStyle,
  addresSpanStyle,
  copyrightStyle,
  socialStyle,
}) => {
  const scrollHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const siteQueryData = useStaticQuery(graphql`
    query footerSiteDataQuery {
      site {
        siteMetadata {
          copyright
          description
          contact {
            postal_code
            address
          }
          footerLink {
            company
            privacy
            access
            event
          }
        }
      }
    }
  `);

  const { copyright, contact, description, footerLink } = siteQueryData.site.siteMetadata;
  const { postal_code, address } = contact;
  const { company, privacy, access, event } = footerLink;

  return (
    <FooterWrap>
      {/* <FooterTop>
				<Logo {...logoStyle} />
				<div className="ft-menu">
					<FooterMenu />
				</div>
			</FooterTop> */}
      <FooterMiddle>
        <FooterMiddleLeft>
          <Heading {...headingStyle}>X-HACK</Heading>
          {description && (
            <Text {...emailTextStyle}>
              <Link {...emailLinkStyle}>{description}</Link>
            </Text>
          )}
        </FooterMiddleLeft>
        <FooterMiddleRight>
          {/* <Heading {...widgetTitleStyle}>所在地</Heading>
          <AddressWrap>
            {postal_code && (
              <Address>
                <Text {...addresTextStyle}>
                  <Text {...addresSpanStyle}>Postal Address:</Text>
                  {parse(postal_code)}
                </Text>
              </Address>
            )}
            {address && (
              <Address>
                <Text {...addresTextStyle}>
                  <Text {...addresSpanStyle}>Address</Text>
                  {parse(address)}
                </Text>
              </Address>
            )}
          </AddressWrap>
          <ScrollTop className="creative-cursor" onClick={scrollHandler}>
            <div className="arrow-top"></div>
            <div className="arrow-top-line"></div>
          </ScrollTop> */}
        </FooterMiddleRight>
      </FooterMiddle>
      <FooterBottom>
        {copyright && (
          <FooterBottomLeft>
            <Text {...copyrightStyle}>
              {new Date().getFullYear()} {copyright}
            </Text>
          </FooterBottomLeft>
        )}
        <FooterBottomRight>
          <Social {...socialStyle}>
            {company && <SocialLink path={company}>company</SocialLink>}
            {access && <SocialLink path={access}>access</SocialLink>}
            {privacy && <SocialLink path={privacy}>privacy</SocialLink>}
            {event && <SocialLink path={event}>events</SocialLink>}
            {/* <SocialLink path="https://share.hsforms.com/1BtD7zqVFSN2VqHlwbJARHQ5czrb">contact</SocialLink> */}
          </Social>
        </FooterBottomRight>
      </FooterBottom>
    </FooterWrap>
  );
};

Footer.propTypes = {
  logoStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  emailTextStyle: PropTypes.object,
  emailLinkStyle: PropTypes.object,
  widgetTitleStyle: PropTypes.object,
  addresTextStyle: PropTypes.object,
  addresSpanStyle: PropTypes.object,
  copyrightStyle: PropTypes.object,
  socialStyle: PropTypes.object,
};

Footer.defaultProps = {
  logoStyle: {
    responsive: {
      medium: {
        maxwidth: "125px",
      },
    },
  },
  headingStyle: {
    as: "h1",
    color: "#f8f8f8",
    fontSize: "100px",
    fontweight: 700,
    lineHeight: 1,
    responsive: {
      xlarge: {
        fontSize: "65px",
      },
      medium: {
        fontSize: "30px",
      },
    },
  },
  emailTextStyle: {
    m: 0,
  },
  emailLinkStyle: {
    color: "#fff",
    fontSize: "30px",
    fontweight: 500,
    opacity: 0.7,
    responsive: {
      xlarge: {
        fontSize: "20px",
      },
      medium: {
        fontSize: "16px",
      },
    },
  },
  widgetTitleStyle: {
    color: "#c1c1c1",
    fontSize: "16px",
    fontweight: 600,
    texttransform: "uppercase",
    letterspacing: "1px",
    responsive: {
      small: {
        fontSize: "14px",
      },
    },
  },
  addresTextStyle: {
    color: "#fff",
    opacity: 0.7,
    responsive: {
      medium: {
        fontSize: "12px",
        lineHeight: "24px",
      },
    },
  },
  addresSpanStyle: {
    as: "span",
    display: "block",
  },
  copyrightStyle: {
    color: "#fff",
    m: 0,
    fontSize: "14px",
    letterspacing: "1px",
    fontweight: 400,
    opacity: 0.7,
    responsive: {
      medium: {
        fontSize: "10px",
        letterspacing: "0.5px",
      },
    },
  },
  socialStyle: {
    opacity: 0.7,
    space: 30,
    responsive: {
      medium: {
        space: 20,
        fontSize: "12px",
      },
    },
  },
};

export default Footer;
